.news-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin: 0 auto 2rem;
  max-width: 800px;
}

.news-tile {
  align-items: flex-start;
  background-color: var(--white, #ffffff);
  border-radius: var(--border-radius, 8px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  width: 100%;
  min-height: 200px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: 1px solid transparent;
  position: relative;
}

.news-tile:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background-color: #f8f8f8;
  border-color: #e0e0e0;
}


.news-tile__title {
  color: var(--black, #000000);
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0.5rem 0;
  text-align: left;
  width: 100%;
}

.news-tile__description {
  color: var(--black, #000000);
  font-size: 1.4rem;
  line-height: 1.6;
  margin: 0.5rem 0;
  text-align: left;
  flex-grow: 1;
}

.news-tile__date {
  color: var(--aluminium, #888);
  font-size: 0.9rem;
  margin-top: 1rem;
  align-self: flex-end;
}

.news-tile__button {
  margin-top: 1rem;
  align-self: flex-start;
}

@media (max-width: 768px) {
  .news-grid {
    padding: 0 1rem;
  }
}