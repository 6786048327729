.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background: none;
  border: none;
  font-size: 24px;
  padding: 8px;
  cursor: pointer;
  color: #000;
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
  margin-bottom: 8px;
  pointer-events: none;
}

.dropdown-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}

.dropdown-button:hover .dropdown-tooltip {
  opacity: 1;
  visibility: visible;
}

.dropdown-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}
 
.dropdown-menu {
  position: absolute;
  right: 0;
 background-color: var(--white);
 border-radius: 8px;
 min-width: 200px;
 z-index: 50;
 margin-top: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.dropdown-menu {
  right: -10px;
}

.dropdown-menu {
  z-index: 1001;
}

.dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  background: none;
  border: none;
  color: var(--black);
  cursor: pointer;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.2;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.dropdown-item:hover {
  background-color: #f8f8f8;
  border-radius: 8px;
}
