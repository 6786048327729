.content-layout {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  min-height: 640px;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
}

.content-columns {
  display: flex;
  gap: 2rem;
}

.content-sidebar {
  width: 350px;
  margin-left: -50px;
}

.content-main {
  flex: 1;
}

.admin-page .page-content .content-layout {
  max-width: 1400px;
}
 
.content__title {
  margin-top: 0;
  color: var(--black) !important;
  text-align: center;
}
 
.content__body {
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

#page-description {
  display: flex;
  flex-direction: column;
}

#page-description span {
  margin-bottom: 1.6rem;
}

#page-description span:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 480px) {
  .content-layout {
    padding: 1.6rem;
  }

  .content__title {
    font-size: 2.4rem;
  }
}
