.page-background {
    min-height: 100vh;
    background: #ffffff;
    position: relative;
    color: #000000;
}

.page-content {
    position: relative;
    z-index: 1;
    padding-top: 2rem;
}
