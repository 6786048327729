.home-page {
    min-height: 100vh;
    background: url('../assets/background_dark.png') no-repeat center center;
    background-size: cover;
    position: relative;
    color: #000000;
}

.home-page-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.customer-portal-title {
    text-align: center;
}

.customer-portal-title h1 {
    font-size: 10rem;
    color: #ffffff;
    font-family: 'Archivo', sans-serif;
    margin: 0;
    padding: 20px;
}

.customer-portal-subtitle {
    font-size: 4rem;
    color: #ffffff;
    font-family: 'Archivo', sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 10px 20px 20px;
}
