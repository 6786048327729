:root {
  --white: #ffffff;
  --black: #000000;
  --indigo: #44189D;
  --background-color: rgba(255, 255, 255, 1);
  --background-color-light: rgba(255, 255, 255, 1);
  --danger: #dc3545;
  --border-radius: 8px;
  --spacing-md: 1rem;
  --spacing-lg: 2rem;
}

.content__body {
  background-color: var(--white);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius);
}

.content__subheading {
  color: var(--black);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
}

.content__subheading:first-of-type {
  margin-top: 0;
}

.form-layout {
  background-color: var(--background-color-light);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius);
  margin-bottom: var(--spacing-lg);
}

.form-separator {
  margin: var(--spacing-lg) 0;
  border-top: 1px solid var(--background-color);
}

.products-links {
  margin-bottom: var(--spacing-lg);
  padding: var(--spacing-md);
  background-color: var(--background-color-light);
  border-radius: var(--border-radius);
}

.products-links__text {
  color: var(--white);
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.products-links__text:last-child {
  margin-bottom: 0;
}

.products-links__link {
  color: var(--indigo);
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
}

.products-links__link:hover {
  opacity: 0.8;
  text-decoration: underline;
}


.resources-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 0 auto 2rem;
  max-width: 1200px;
}

.content-columns {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.content-main {
  width: 100%;
}

@media (max-width: 768px) {
  .resource-tile {
    width: 100%;
    min-width: 250px;
  }
  
  .resources-grid {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
  
  .resource-tile__description {
    height: auto;
    max-width: 90%;
  }
}

.news-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin: 0 auto 2rem;
  max-width: 800px;
}

.news-grid .resource-tile .button {
  width: 200px;
  margin: 0 auto;
  display: block;
}

.resource-tile {
  align-items: center;
  background-color: var(--white);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  width: 18vw;
  min-height: 280px;
  height: 100%;
  transition: all 0.2s ease-in-out;
}

.resource-tile__icon {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.resource-tile__icon svg {
  width: 100px;
  height: 100px;
}

.resource-tile:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background-color: #f8f8f8;
}

.resource-tile--disabled {
  background-color: var(--background-color-light);
}

.resource-tile__title {
  color: var(--black);
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0.5rem 0;
  text-align: center;
  width: 100%;
}

.resource-tile__description {
  color: var(--black);
  font-size: 1.4rem;
  line-height: 1.6;
  margin: 0.5rem 0;
  text-align: center;
  flex-grow: 0;
  height: 4.8em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
}

.button {
  width: 100%;
  padding: 0.75rem var(--spacing-md);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
}

.button--primary {
  background-color: #44189D;
  color: var(--white);
}

.button--primary:hover {
  opacity: 0.9;
}

.button--request-access {
  position: relative;
  overflow: hidden;
}

.button--request-access .button__text,
.button--request-access .button__hover-text {
  transition: transform 0.3s ease;
  display: block;
  width: 100%;
}

.button--request-access .button__hover-text {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--indigo);
  transform: translateY(100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.button--request-access:hover .button__text {
  transform: translateY(-100%);
}

.button--request-access:hover .button__hover-text {
  transform: translateY(0);
}

.message {
  margin-top: var(--spacing-md);
  padding: var(--spacing-md);
  border-radius: 4px;
  background-color: var(--background-color);
  color: var(--white);
}

.error-message {
  color: var(--white);
  margin: 0;
}

.message a {
  color: var(--white);
  font-weight: bold;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
}

.resource-tile {
  transition: all 0.2s ease-in-out;
}

.resource-tile:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background-color: #f8f8f8;
}

.resource-tile--disabled {
  background-color: var(--background-color-light);
  opacity: 0.8;
}

.resource-tile--disabled:hover {
  background-color: #f0f0f0;
}


.resource-tile__request-access {
  color: var(--indigo);
  font-weight: 600;
  text-align: center;
  padding-top: 0.5rem;
  width: 100%;
}

.button,
.button--primary,
.button--request-access {
  display: block;
}
