:root {
  --white: #ffffff;
  --indigo: #44189D;
  --background-gradient: linear-gradient(160deg, #100a70 40.44%, #7a0c73 70.67%);
}

.account-management-page {
  min-height: 100vh;
  background-image: 
      url('../assets/bg1.png'),
      url('../assets/bg2.png'),
      url('../assets/bg3.png'),
      var(--background-gradient);
  background-position: left -300px, 90% -30px, right -30px, 0 0;
  background-repeat: no-repeat;
  background-size: auto;
  background-attachment: fixed;
  position: relative;
  color: var(--white);
}

.button-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.content__body {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 8px;
}

.content__subheading {
  color: var(--white);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.form-button {
  width: auto;
  padding: 0.75rem 1.6rem;
  margin: 0.5rem 0;
}

.form-separator {
  margin: 1rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0);
}

.form-layout {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 8px;
  margin: 0 auto 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  max-width: 800px;
}

.form-layout:last-child {
  margin-bottom: 0;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--black);
  font-size: 1.6rem;
  font-weight: normal;
}

.form-layout p {
  color: var(--black);
  font-size: 1.6rem;
}

.form-group input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.9);
  color: var(--black);
  font-size: 1rem;
}

.token-textarea {
  width: 100%;
  height: auto;
  padding: 10px;
  font-family: 'monospace';
  font-size: 14px;
  resize: 'none';
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: hidden;
  line-height: normal;
}

.form-group input:focus {
  outline: none;
  border-color: var(--indigo);
  background-color: rgba(255, 255, 255, 1);
}

.button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
}

.button--primary {
  background-color: #44189D;
  color: var(--white);
}

.message {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--white);
}

.debug-output, .debug-info {
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--white);
  border-radius: 4px;
  font-family: 'Archivo', sans-serif;
  white-space: pre-line;
  word-wrap: break-word;
}

.user-information pre {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 3px;
  overflow-x: auto;
}

@media (max-width: 640px) {
  .form-button {
    width: 100%;
    max-width: none;
  }
}

.password-requirements-toast {
  background-color: #000;
  color: white;
  padding: 12px 16px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  width: 350px;
  position: absolute;
  z-index: 1000;
  font-size: 1.275rem;
  right: auto;
  left: 0;
  top: calc(100% + 10px);
}

.password-requirements-toast::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 20px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #000 transparent;
}

.password-note {
  color: white !important;
  font-style: italic;
  font-size: 1.275rem;
  margin-top: 10px;
  margin-bottom: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 8px;
}

.password-requirements-toast h4 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 1.275rem;
  font-weight: normal;
}

.password-requirements-toast ul {
  margin: 0;
  padding-left: 15px;
  list-style-type: none;
}

.password-requirements-toast li {
  margin-bottom: 8px;
  position: relative;
  padding-left: 5px;
  font-size: 1.275rem;
  line-height: 1.4;
}

.password-requirements-toast li::before {
  content: "•";
  position: absolute;
  left: -12px;
  font-size: 1.275rem;
}

.requirement-pending {
  color: white;
}

.requirement-met {
  color: #4CAF50;
}

.requirement-failed {
  color: #F44336;
}

.password-match-toast {
  background-color: #000;
  color: white;
  padding: 12px 16px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  width: 280px;
  position: absolute;
  z-index: 1000;
  font-size: 1.275rem;
  right: auto;
  left: 0;
  top: calc(100% + 10px);
}

.password-match-toast::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 20px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #000 transparent;
}

.match-status {
  font-size: 1.275rem;
  display: block;
  text-align: center;
}

.match-status.match {
  color: #4CAF50;
}

.match-status.mismatch {
  color: #F44336;
}

.logout-notification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.logout-notification-dialog {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 90%;
  text-align: center;
}

.logout-notification-dialog h3 {
  margin-top: 0;
  color: #000;
}

.logout-notification-dialog p {
  margin-bottom: 20px;
  color: #333;
}

.logout-countdown {
  font-weight: bold;
  color: #44189D !important;
}
