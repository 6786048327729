.users-list {
  overflow-x: auto;
  width: 100%;
  margin: 20px 0;
}

.admin-page .form-layout {
  max-width: 1000px;
}

.users-table {
  width: 100%;
  min-width: 1250px;
  border-collapse: separate;
  border-spacing: 0;
  background: transparent;
}

.users-table th,
.users-table td {
  padding: 20px;
  text-align: left;
  border-bottom: 1px solid var(--light-aluminium);
  color: var(--black);
  font-size: 14px;
  line-height: 1.4;
}

.users-table th {
  background: transparent;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--black);
  font-size: 13px;
  border-bottom: 2px solid var(--black);
}

.users-table tr:last-child td {
  border-bottom: none;
}

.users-table tbody tr {
  transition: all 0.2s ease-in-out;
}

.users-table tbody tr:hover {
  background: #f8f9fa;
}

.users-table td:last-child {
  width: 50px;
  text-align: center;
}

.users-table tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

.users-table tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

.users-table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.users-table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
 min-width: 400px;
 max-width: 90%;
 width: auto;
 border-radius: 12px;
 background-color: var(--white);
}

.modal-content {
  padding: 2rem;
}

.modal-content h2 {
  color: var(--white);
  color: var(--black);
  margin: 0 0 1rem;
}

.confirmation-text {
  color: var(--black);
  text-align: center;
  margin: 1.5rem 0;
  line-height: 1.5;
  word-wrap: break-word;
}

.user-email {
  display: inline-block;
  font-weight: 500;
  margin-top: 0.5rem;
  word-break: break-all;
}

.modal-buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 2rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--white);
}

.form-group input[type="text"],
.form-group input[type="email"] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--aluminium);
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  color: var(--black);
  font-size: 1.5rem;
}

.form-group input[type="text"]:disabled,
.form-group input[type="email"]:disabled {
  background: rgba(255, 255, 255, 0.05);
  cursor: not-allowed;
}

.form-group input[type="checkbox"] {
  margin-right: 8px;
  cursor: pointer;
  width: auto;
}

.domain-info {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 2rem;
}

.domain-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.domain-tag {
  background: var(--indigo);
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.button--danger {
  background-color: #dc3545;
  color: var(--white);
}

.button--danger:hover {
  background-color: #c82333;
}

.error-message {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid rgba(220, 53, 69, 0.2);
}

.toast-notification {
  position: fixed;
  top: 96px;
  right: 24px;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 20px 32px;
  border-radius: 12px;
  z-index: 9999;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  animation: slideInRight 0.3s ease-out;
  font-size: 1.3rem;
  font-weight: 500;
  min-width: 300px;
  text-align: center;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.toast-notification.fade-out {
  animation: fadeOut 0.3s ease-out forwards;
}

@keyframes fadeOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
