.help-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.help-page-container {
  display: flex;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.help-sidebar {
  width: 250px;
  position: sticky;
  top: 100px;
  align-self: flex-start;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  padding-right: 15px;
  border-right: 1px solid var(--light-aluminium);
}

.help-main-content {
  flex: 1;
}

.help-sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.help-sidebar li {
  margin-bottom: 10px;
}

.help-sidebar a {
  color: var(--dark-aluminium);
  text-decoration: none;
  display: block;
  padding: 5px 0;
  font-size: 14px;
}

.help-sidebar a:hover {
  color: var(--primary-color);
}

.help-sidebar h3 {
  margin-top: 0;
  font-size: 16px;
  color: var(--primary-color);
  border-bottom: 1px solid var(--aluminium);
  padding-bottom: 8px;
  margin-bottom: 15px;
}

.help-sidebar .section-link {
  font-weight: 600;
}

.help-sidebar .subsection-link {
  padding-left: 15px;
  font-size: 13px;
}

.help-sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toc-toggle-mobile {
  display: none;
}

@media (max-width: 768px) {
  .help-page-container {
    flex-direction: column;
    padding: 0 10px;
  }
  
  .help-sidebar {
    width: 100%;
    position: static;
    border-right: none;
    border-bottom: 1px solid var(--light-aluminium);
    padding-bottom: 15px;
    margin-bottom: 20px;
    max-height: none;
    overflow-y: visible;
    padding: 15px;
  }
  
  .help-content {
    padding: 10px;
  }
  
  .help-content img {
    max-width: 100%;
    height: auto;
  }
  
  .help-sidebar a {
    padding: 8px 0;
    font-size: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .help-sidebar a:last-child {
    border-bottom: none;
  }
  
  .help-sidebar .subsection-link {
    padding-left: 15px;
    font-size: 15px;
    margin: 2px 0;
    padding: 8px 15px;
    font-weight: 500;
  }
  
  .help-sidebar li {
    margin-bottom: 5px;
  }
  
  .help-content p, 
  .help-content li {
    font-size: 16px;
    line-height: 1.7;
  }
  
  .help-content section {
    margin-bottom: 25px;
  }
  
  section[id] {
    scroll-margin-top: 100px;
  }
  
  .toc-toggle-mobile {
    display: none;
  }
  
  .toc-list {
    display: block;
    margin-top: 15px;
    background-color: transparent;
    padding: 10px 0;
    border: none;
    transition: none;
  }
  
  .toc-list.show-mobile {
    display: block;
    animation: none;
  }
  
  @keyframes fadeIn {
    from { opacity: 1; transform: none; }
    to { opacity: 1; transform: none; }
  }
}

@media (max-width: 480px) {
  .help-content {
    padding: 5px;
  }
  
  .help-content h1 {
    font-size: 24px;
  }
  
  .help-content h2 {
    font-size: 20px;
  }
  
  .help-content h3 {
    font-size: 18px;
  }
  
  .content__title {
    font-size: 22px;
    text-align: center;
  }
  
  .help-page-container {
    padding: 0 5px;
  }
}

.help-content section {
  margin-bottom: 30px;
}

.help-content h2 {
  margin-top: 20px;
  margin-bottom: 15px;
  color: var(--primary-color);
  border-bottom: 1px solid var(--aluminium);
  padding-bottom: 8px;
}

.help-content h3 {
  margin-top: 15px;
  margin-bottom: 10px;
  color: var(--secondary-color);
}

.help-content p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.help-content ul, .help-content ol {
  margin-left: 20px;
  margin-bottom: 15px;
}

.help-content li {
  margin-bottom: 8px;
  line-height: 1.5;
}

.help-content a {
  color: rgba(68, 24, 157, 0.85);
  text-decoration: none;
  font-weight: 500;
}

.help-content a:hover {
  text-decoration: underline;
  color: rgba(83, 45, 159, 0.85);
}

.help-content code {
  background-color: #f5f5f5;
  padding: 2px 5px;
  border-radius: 3px;
  font-family: monospace;
}

.help-content img {
  max-width: 100%;
  height: auto;
  border: 1px solid var(--border-color, #ddd);
  border-radius: 4px;
  margin: 15px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.help-content h1 {
  color: var(--primary-color);
  margin-bottom: 20px;
  border-bottom: 1px solid var(--aluminium);
  padding-bottom: 8px;
}

.help-content h4, .help-content h5 {
  margin-top: 15px;
  margin-bottom: 10px;
  color: var(--secondary-color);
}

section[id] {
  scroll-margin-top: 80px;
}
