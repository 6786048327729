.page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.page-layout__content {
  flex: 1;
  width: 100%;
  max-width: 1400px;
  margin-top: 8rem;
  padding: 0 2rem;
}

@media only screen and (max-width: 640px) {
  .page-layout__content {
    margin-top: 6.4rem;
  }
}
